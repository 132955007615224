// extracted by mini-css-extract-plugin
export var blogPostPreview = "blog-module--blogPostPreview--sWfjw";
export var cardBody = "blog-module--cardBody--C6xuS";
export var cardTitleLink = "blog-module--cardTitleLink--1eNOZ";
export var headerText = "blog-module--headerText--YeyBQ";
export var infoText = "blog-module--infoText--PrrqN";
export var pageNavigationButtons = "blog-module--pageNavigationButtons--lIJwH";
export var postMetadata = "blog-module--postMetadata--SCESQ";
export var postMetadataItem = "blog-module--postMetadataItem--M1imO";
export var postTag = "blog-module--postTag--pzpdH";
export var postTagContainer = "blog-module--postTagContainer--hdmdM";
export var tagList = "blog-module--tagList--WmfAB";
export var tagListContainer = "blog-module--tagListContainer--YdVBg";
export var tagListItem = "blog-module--tagListItem--TvZ-6";