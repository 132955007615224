import React, { useState } from "react"
import { Container, Row, Col, Card, Form, Button, InputGroup } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import startCase from "lodash/startCase"
import capitalize from "lodash/capitalize"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { headerText, infoText, tagListContainer, tagList, tagListItem, blogPostPreview, cardTitleLink, cardBody, postMetadata, postMetadataItem, postTagContainer, postTag, pageNavigationButtons } from "../styles/blog.module.css"

const BlogList = ({ data, pageContext }) => {
  const [posts, setPosts] = useState(data.allMarkdownRemark.edges);
  // const [query, setQuery] = useState("");
  const [postCount, setPostCount] = useState(data.totalCount.postCount);
  const { tags } = data.totalCount
  const { currentPage, numPages, limit: postLimit } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const baseUrl = "/blog"
  const prevPage = currentPage - 1 === 1 ? baseUrl : `${baseUrl}/${(currentPage - 1).toString()}`
  const nextPage = `${baseUrl}/${(currentPage + 1).toString()}`

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  // useEffect(() => {
  //   var filteredPosts = data.allMarkdownRemark.edges
  //     .filter(post => post.node.frontmatter.title.length > 0)
  //     .filter(post => {
  //       if (query === '') {
  //         return post;
  //       } else if (
  //         post.node.frontmatter.title.toLowerCase().includes(query.toLowerCase()) ||
  //         post.node.frontmatter.series.toLowerCase().includes(query.toLowerCase()) ||
  //         post.node.frontmatter.tags.some(tag => { return tag.toLowerCase().includes(query.toLowerCase()) }) ||
  //         post.node.html.toLowerCase().includes(query.toLowerCase())
  //       ) {
  //         return post;
  //       }
  //       return null;
  //     })
  //   setPosts(filteredPosts);
  //   setPostCount(filteredPosts.length);
  // }, [query])

  return (
    <Layout>
      <Container fluid className="text-white">
        <Row className={[tagListContainer]}>
          <Col xs={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} className={[tagList]}>
            {tags && shuffle(tags).slice(0, 10).map(({ fieldValue: tag }) => {
              return (
                <Button variant="outline-info" href={`/tags/${kebabCase(tag)}`} key={tag} className={[tagListItem]}>{startCase(tag)}</Button>
              )
            })}
            <Button variant="info" href="/tags" className={[tagListItem]}>Find all tags</Button>
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-center lead">
            <h1 className={[headerText]}>My Digital Garden</h1>
            <span className={[infoText]}>
              <p>Please come and enjoy a little insight into my mind and my journey through life.</p>
              <p>
                I call this my digital garden as I'm planting new seeds of information
                to ultimately grow into a garden of knowledge for others.
              </p>
              <p>
                While I try to only publish finished works, there may be times
                that I go back to "prune" or "water" my previous thoughts.
              </p>
              <p>
                Don't worry though, the latest information will always
                display at the top of the list.
              </p>
            </span>
          </Col>
          <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-center lead">
            <span className={[infoText]}>
              <p>
                If a post you're enjoying is part of a series, be sure to click
                on the related link in the post and read on.
              </p>
              <p>
                You can also find all blog series by clicking the button below!
              </p>
            </span>
          </Col>
          <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-center lead">
            <Link to="/series">
              <Button variant="info" size="lg">Find all series</Button>
            </Link>
          </Col>
        </Row>
        {/* Implement with Search Functionality
        <Row className="justify-content-center">
          <Col xl={{ span: 5, offset: 1 }} md={{ span: 8, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }}>
            <Form className="d-flex">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  onChange={event => setQuery(event.target.value)}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row> */}
        <Row className="justify-content-center mb-5">
          <Col xl={{ span: 5, offset: 1 }} md={{ span: 8, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-justify">
            <p className="d-block mt-2 text-center">Posts {((currentPage - 1) * postLimit) + 1} - {currentPage === numPages ? postCount : currentPage * postLimit} of {postCount}</p>
            <div className="blog-posts">
              {posts.map(({ node: post }, index) => {
                return (
                  <div key={post.id}>
                    <Card bg="white" text="dark" className={[blogPostPreview]}>
                      {post.frontmatter.banner &&
                        <GatsbyImage
                          image={getImage(post.frontmatter.banner)}
                          alt="Banner Image" />
                      }
                      <Card.Body>
                        <Card.Title className="mb-0">
                          <h2 className="text-center">
                            <Link to={post.frontmatter.path} className={[cardTitleLink]}>{post.frontmatter.title}</Link>
                          </h2>
                        </Card.Title>
                        {post.frontmatter.series.length > 0 &&
                          <Card.Subtitle className="text-muted mb-1">
                            Series:
                            <Link to={`/series/${kebabCase(post.frontmatter.series)}`} className="ps-1 text-primary">{post.frontmatter.series.replace(/\w+/g, capitalize)}</Link>
                          </Card.Subtitle>
                        }
                        <Card.Text className={cardBody}>
                          {post.frontmatter.description}
                        </Card.Text>
                        <Card.Text className="lead mb-0">
                          <Link to={post.frontmatter.path} className="text-primary"><small>Read more...</small></Link>
                        </Card.Text>
                        <Row className={[postMetadata]}>
                          <Col xs={12}>
                            <Card.Subtitle className={[postMetadataItem]}>Created On: {post.frontmatter.dateCreated}</Card.Subtitle>
                          </Col>
                          {post.frontmatter.dateCreated !== post.frontmatter.dateEdited &&
                            <Col xs={12}>
                              <Card.Subtitle className={[postMetadataItem]}>Edited On: {post.frontmatter.dateEdited}</Card.Subtitle>
                            </Col>
                          }
                          <Col xs={12}>
                            <Card.Subtitle className={[postMetadataItem]}>Read Time: {post.timeToRead} {post.timeToRead === 1 ? 'min' : 'mins'}</Card.Subtitle>
                          </Col>
                          {/* Implement when Comment functionality goes live
                          <Col xs={12}>
                            <Card.Subtitle className={[styles.postMetadataItem]}>Comments: 0</Card.Subtitle>
                          </Col> */}
                        </Row>
                      </Card.Body>
                      <Card.Footer className="text-dark d-flex align-items-baseline py-1">
                        <small className="align-self-center">Tags:</small>
                        <Col className={[postTagContainer]}>
                          {post.frontmatter.tags.length > 0
                            ? post.frontmatter.tags.map(tag => {
                              return (
                                <Button variant="link" href={`/tags/${kebabCase(tag)}`} key={tag} className={[postTag]}><small>{startCase(tag)}</small></Button>
                              )
                            })
                            : <small className="ps-1">uncategorized</small>
                          }
                        </Col>
                      </Card.Footer>
                    </Card>
                    {(index !== posts.length - 1) &&
                      <hr />
                    }
                  </div>
                );
              })}
            </div>
          </Col>
          {numPages > 1 &&
            <Row className="justify-content-center">
              <Col xl={{ span: 5, offset: 1 }} md={{ span: 8, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-center">
                <Row className="align-items-center">
                  <Col xs={4}>
                    {!isFirst && (
                      <Link to={prevPage} rel="prev" role="none" tabIndex="-1">
                        <Button variant="outline-info" className={[pageNavigationButtons]} id="prevPageButton" aria-label="Previous Page Button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                          </svg>
                        </Button>
                      </Link>
                    )}
                  </Col>
                  <Col xs={4}>
                    {Array.from({ length: numPages }, (_, i) => (
                      i === (currentPage - 1) ?
                        <Link key={`pagination-number${i + 1}`} to={`${baseUrl}${i === 0 ? "" : "/" + (i + 1)}`} role="none" tabIndex="-1">
                          <Button variant="info" className={[pageNavigationButtons]} id="currPageButton" aria-label="Current Page Button">
                            {i + 1}
                          </Button>
                        </Link>
                        :
                        <Link key={`pagination-number${i + 1}`} to={`${baseUrl}${i === 0 ? "" : "/" + (i + 1)}`} role="none" tabIndex="-1">
                          <Button variant="outline-info" className={[pageNavigationButtons]} id={`page${i + 1}Button`} aria-label={`Page ${i + 1} Button`}>
                            {i + 1}
                          </Button>
                        </Link>
                    ))}
                  </Col>
                  <Col xs={4}>
                    {!isLast && (
                      <Link to={nextPage} rel="next" role="none" tabIndex="-1">
                        <Button variant="outline-info" className={[pageNavigationButtons]} id="nextPageButton" aria-label="Next Page Button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                          </svg>
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        </Row>
      </Container>
    </Layout>
  );
}

export const Head = (props) => {
  const pageSeo = {
    title: "My Digital Garden Blog Posts",
    description: "Get motivated to make a change in your life and become a better software engineer with some practical programming advice through quick blog posts.",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

export default BlogList

export const blogListQuery = graphql`query blogListQuery($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___dateEdited]}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        excerpt(pruneLength: 250)
        id
        frontmatter {
          title
          description
          dateCreated(formatString: "MMMM DD, YYYY")
          dateEdited(formatString: "MMMM DD, YYYY")
          path
          series
          tags
          banner {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        html
        timeToRead
      }
    }
  }
  totalCount: allMarkdownRemark {
    postCount: totalCount
    tags: group(field: frontmatter___tags) {
      fieldValue
    }
  }
}
`